<template>
  <div :class="{ paddedTopBottom: paddedTopBottom, padded: padded }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Home",
  props: {
    padded: {
      type: Boolean,
      default: true
    },
    paddedTopBottom: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="less" scoped>
.padded {
  margin-top: 3rem;
}

.paddedTopBottom {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

@media (max-width: 600px) {
  .padded {
  }
}

@media (max-width: 960px) {
  .padded {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 1264px) {
  .padded {
  }
}

@media (max-width: 1904px) {
  .padded {
  }
}

@media (min-width: 600px) {
  .padded {
  }
}

@media (min-width: 960px) {
  .padded {
    margin-right: 8.3333333333%;
  }
}

@media (min-width: 1264px) {
  .padded {
    margin-right: 16.6666666667%;
  }
}

@media (min-width: 1904px) {
  .padded {
  }
}
</style>
