import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../views/Contact.vue")
  },
  {
    path: "/osteopathie",
    name: "Osteopathie",
    component: () =>
      import(/* webpackChunkName: "Osteopathie" */ "../views/Osteopathie.vue")
  },
  {
    path: "/werkwijze",
    name: "Werkwijze",
    component: () =>
      import(/* webpackChunkName: "Werkwijze" */ "../views/Werkwijze.vue")
  },
  {
    path: "/tarieven",
    name: "Tarieven",
    component: () =>
      import(/* webpackChunkName: "Tarieven" */ "../views/Tarieven.vue")
  },
  {
    path: "/over-ons",
    name: "OverOns",
    component: () =>
      import(/* webpackChunkName: "OverOns" */ "../views/OverOns.vue")
  },
  {
    path: "/over-ons/daan",
    name: "Daan",
    component: () =>
      import(/* webpackChunkName: "Daan" */ "../views/team/Daan.vue")
  },
  {
    path: "/over-ons/klaas",
    name: "Klaas",
    component: () =>
      import(/* webpackChunkName: "Klaas" */ "../views/team/Klaas.vue")
  },
  {
    path: "/over-ons/kim",
    name: "Kim",
    component: () =>
      import(/* webpackChunkName: "Kim" */ "../views/team/Kim.vue")
  },
  {
    path: "/over-ons/ronald",
    name: "Ronald",
    component: () =>
      import(/* webpackChunkName: "Ronald" */ "../views/team/Ronald.vue")
  },
  {
    path: "/over-ons/serge",
    name: "Serge",
    component: () =>
      import(/* webpackChunkName: "Serge" */ "../views/team/Serge.vue")
  },
  {
    path: "/over-ons/liesbeth",
    name: "Liesbeth",
    component: () =>
      import(/* webpackChunkName: "Liesbeth" */ "../views/team/Liesbeth.vue")
  },
  {
    path: "/over-ons/dinesh",
    name: "Dinesh",
    component: () =>
      import(/* webpackChunkName: "Dinesh" */ "../views/team/Dinesh.vue")
  },
  {
    path: "/over-ons/jeroen",
    name: "Jeroen",
    component: () =>
      import(/* webpackChunkName: "Jeroen" */ "../views/team/Jeroen.vue")
  },
  {
    path: "/over-ons/ryanne",
    name: "Ryanne",
    component: () =>
      import(/* webpackChunkName: "Ryanne" */ "../views/team/Ryanne.vue")
  },
  {
    path: "/over-ons/leonore",
    name: "Leonore",
    component: () =>
      import(/* webpackChunkName: "Leonore" */ "../views/team/Leonore.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
